import axios from 'axios'
import store from '../store'
import {TEXT_MODULE} from '@/api/_prefix'
import router from '../router'

export const getTextDetail = oneText => {
  return axios.post(`${TEXT_MODULE}/getTextDetail`, oneText,
    {
      headers: {
        Authorization: store.state.token
      }
    }).then(res => {
    return res.data
  }).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  });
}

/**
 * 未登录时获取公开文本
 * @param {*} oneText
 * @returns
 */
export const getTextDetailNotLogin = oneText => {
  return axios.post(`${TEXT_MODULE}/getTextDetail`, oneText).then(res => {
    return res.data
  })
}
